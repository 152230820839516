
// initial state
const initialState = {
    qr: "",
    loading: false,
    disabled: false,
    unMount: undefined,
    authenticated: false,
    email: "",
    emailError: "",
    password: "",
    passwordError: "",
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    image: null,
    imageError: "",
    logo: null,
    logoError: "",
    abouts: [],
    products: [],
    services: [],
    title: "",
    titleError: "",
    phoneNumber: "",
    phoneNumberError: "",
    instagram: "",
    instagramError: "",
    vcard: null,
    vcardError: "",
    whatsapp: "",
    whatsappError: "",
    color: "#ED1C24",
    colorError: "",
    country: "",
    countryError: "",
    region: "",
    regionError: "",
    location: "",
    locationError: "",
    edit: false,
    id: "",
    cards: [],
    oldImage: "",
    oldLogo: "",
    oldVcard: "",
    folderName: "",
    address: ""
}

// exporting initial state
export default initialState